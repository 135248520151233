<template>
  <a-card :border="false">
    <div class="realtor">
      <!-- 查询表单 -->
      <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 8 }" :wrapper-col="{ xs: 16 }">
        <a-row :gutter="8">
          <!-- <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="经销商">
              <a-input v-model="searchData.name" placeholder="请输入经销商名称"></a-input>
            </a-form-model-item>
          </a-col> -->
<!--          <a-col :md="8" :xl="6" :xxl="4" :sm="12">-->
<!--            <a-form-model-item label="预订单状态">-->
<!--              <DictSelect field="advanceOrder" :value.sync="searchData.orderType" style="width: 100%" placeholder="请选择订单状态"></DictSelect>-->
<!--            </a-form-model-item>-->
<!--          </a-col>-->
<!--          <a-col :md="8" :xl="6" :xxl="4" :sm="12">-->
<!--            <a-form-model-item label="申请状态">-->
<!--              <DictSelect-->
<!--                field="registrationStatus"-->
<!--                :value.sync="searchData.registrationStatus"-->
<!--                style="width: 100%"-->
<!--                placeholder="请选择申请状态"-->
<!--              ></DictSelect>-->
<!--            </a-form-model-item>-->
<!--          </a-col>-->
          <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="预订单状态">
              <DictSelect field="advanceOrder" :value.sync="searchData.orderType" style="width: 100%" placeholder="请选择订单状态"></DictSelect>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="申请状态">
              <DictSelect field="registrationStatus" :value.sync="searchData.registrationStatus" style="width: 100%" placeholder="请选择申请状态"></DictSelect>
            </a-form-model-item>
          </a-col>
<!--          <a-col :md="8" :xl="6" :xxl="4" :sm="12">-->
<!--            <a-form-model-item label="经销商">-->
<!--              <a-select placeholder="请选择经销商" v-model="searchData.dealerId" allowClear>-->
<!--                <a-select-option :value="item.id" v-for="(item, index) in dealerData" :key="index">-->
<!--                  {{ item.name }}-->
<!--                </a-select-option>-->
<!--              </a-select>-->
<!--            </a-form-model-item>-->
<!--          </a-col>-->
<!--          <a-col :md="8" :xl="6" :xxl="4" :sm="12" v-if="flagSystem">-->
<!--            <a-form-model-item label="员工">-->
<!--              <a-select placeholder="请选择员工" v-model="searchData.managerId" allowClear>-->
<!--                <a-select-option :value="item.id" v-for="(item, index) in managerData" :key="index">-->
<!--                  {{ item.name }}-->
<!--                </a-select-option>-->
<!--              </a-select>-->
<!--            </a-form-model-item>-->
<!--          </a-col>-->
          <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="客户名称">
              <a-input v-model="searchData.name" placeholder="客户名称" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="联系方式">
              <a-input v-model="searchData.phone" placeholder="联系方式" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="所在地区">
              <PCD
                ref="pcd"
                placeholder="请选择区域"
                :province.sync="searchData.province"
                :city.sync="searchData.city"
                :district.sync="searchData.area"
                :provinceId.sync="searchData.provinceId"
                :cityId.sync="searchData.cityId"
                :districtId.sync="searchData.areaId"
              >
              </PCD>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="物业地址">
              <a-input v-model="searchData.address" placeholder="小区/栋/单元/室" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="申请日期">
              <DateRange :startTime.sync="searchData.createTimeStart" :endTime.sync="searchData.createTimeEnd"></DateRange>
            </a-form-model-item>
          </a-col>
          <!--<a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="成交日期">
              <DateRange :startTime.sync="searchData.dealTimeStart" :endTime.sync="searchData.dealTimeEnd"></DateRange>
            </a-form-model-item>
          </a-col>-->
          <a-col :xxl="4" :xl="5" :md="8" sm="2">
            <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
              <a-button type="default" @click="reset()" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div>
<!--        <a-button v-if="addEnable" type="primary" @click="toHandler('add')">新增</a-button>-->
       <a-button v-if="selectedRows.length > 0" type="primary" @click="toHandler('check')">查看</a-button>
<!--        <a-button v-if="setDealt && selectedRows.length > 0" type="primary" @click="toHandler('set_dealt')">设置已成交</a-button>-->
<!--        <a-button v-if="setDealt && selectedRows.length > 0" type="primary" @click="loseEfficacy">设为失效</a-button>-->
<!--        <a-button v-if="removeEnable && selectedRows.length > 0" type="danger" @click="toHandler('del')">删除</a-button>-->
        <a-button v-if="selectedRows.length > 0 && selectedRows[0].verifyStatus == 1 && selectedRows[0].taskId != null" type="primary" @click="loseEfficacyAuint" >审核</a-button>
<!--        <a-upload-->
<!--          name="file"-->
<!--          :action="uploadUrl"-->
<!--          :headers="headers"-->
<!--          :showUploadList="false"-->
<!--          @change="uploadChange"-->
<!--        >-->
<!--          &lt;!&ndash;          v-if="isImport"&ndash;&gt;-->
<!--          <a-button icon="upload" type="default">导入</a-button>-->
<!--        </a-upload>-->

      </div>
      <!-- 数据表格 -->
      <a-table
        :loading="tableLoading"
        :row-selection="rowSelection"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data-source="tableData"
        bordered
        :pagination="page"
        @change="changeTable"
        :customRow="changeTableRow"
        :rowClassName="rowClassName"
      >
        <span slot="index" slot-scope="text, row, index">{{ index + 1 }}</span>
        <span slot="province" slot-scope="text, row"
          >{{ row.province }}{{ row.city }}{{ row.area }}{{ row.village }}{{ row.building }}{{ row.unit
          }}{{ row.room }}</span
        >
        <span slot="orderType" slot-scope="text, row">
         <a-tag v-if="row.orderType == 1" color="#f50">
            零售
          </a-tag>
          <a-tag v-if="row.orderType == 2" color="#108ee9">
            项目
          </a-tag>
        </span>
        <span slot="propertyType" slot-scope="text, row">
          <a-tag  v-if="row.propertyType == 0" color="green">
            商品房
          </a-tag>
          <a-tag v-if="row.propertyType == 1" color="orange">
           自建房
          </a-tag>
        </span>
        <span slot="orderStatus" slot-scope="text">
          <span v-if="text === 0">未申请</span>
          <span v-if="text === 1">已申请</span>
          <span v-if="text === 2">已成交</span>
          <span v-if="text === 3">已失效</span>
          <span v-if="text === 4">已丢单</span>
          <span v-if="text === 5">已无效</span>
          <span v-if="text === 6">过期</span>
        </span>
        <span slot="registrationStatus" slot-scope="text">
              <span v-if="text === 0">未申请</span>
              <span v-if="text === 1">首次申请</span>
              <span v-if="text === 2">二次申请</span>
              <span v-if="text === 3">申请成功</span>
        </span>
        <span slot="verifyStatus" slot-scope="text">
          <a-tag v-if="text == 0" color="#87d068">待处理</a-tag>
          <a-tag v-if="text == 1" color="#e6a23c">待审核</a-tag>
          <a-tag v-if="text == 2" color="#108ee9">审核通过</a-tag>
          <a-tag v-if="text == 3" color="#ff7875">审核拒绝</a-tag>
          <a-tag v-if="text == 99" color="#e6a23c">审核中</a-tag>
      </span>
        <span slot="sourceType" slot-scope="text">
          <a-tag v-if="text == 0">PC端</a-tag>
          <a-tag v-if="text == 1">小程序</a-tag>
        </span>
        <template slot="action" slot-scope="text,row">
           <a @click="checkStatus(row)" v-if="row.orderStatus == 1 && row.registrationStatus != 0">修改预订单状态</a>
        </template>
      </a-table>
      <!-- 编辑表单 -->
      <DealerCustomerOrder-edit-modal
        ref="DealerCustomerOrderEditModal"
        @reload="getData"
      ></DealerCustomerOrder-edit-modal>
      <DealerCustomerInfoModal ref="DealerCustomerInfoModal"></DealerCustomerInfoModal>
      <SetDealtModal ref="SetDealtModal" @reload="getData"></SetDealtModal>
    </div>

     <a-modal
        title="修改预订单状态"
        :visible="visibleStatus"
        width="40%"
        :confirm-loading="confirmLoading"
        @ok="handleOkManagerName"
        @cancel="handleCancelManagerName"
      >
        <a-form-model>
          <a-form-model-item label="修改状态" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-select v-if="statusChoose.registrationStatus == 1 || statusChoose.registrationStatus == 3 " v-model="managerStatus" style="width: 70%" show-search>
               <!-- <a-select-option :value="''" >请选择</a-select-option> -->
              <a-select-option :value="0">无效客户</a-select-option>
            </a-select>
             <a-select v-if="statusChoose.registrationStatus == 2" v-model="managerStatus" style="width: 70%" show-search>
               <!-- <a-select-option :value="''" >请选择</a-select-option> -->
              <a-select-option :value="1" >首次申请成功</a-select-option>
              <a-select-option :value="0" >无效客户</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </a-modal>

    <!-- 审核 -->
    <a-modal
      v-model="visibleAuint"
      title="审核"
      @ok="handleOkAuint"
      @cancel="handleCanceAuint"
      :maskClosable="false"
      width="40%"
      :confirm-loading="confirmLoadingAuint"
    >
      <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 15 }">
        <a-form-model-item label="是否通过">
          <a-radio-group v-model="statusAuint">
            <a-radio :value="4"> 通过 </a-radio>
            <a-radio :value="3"> 拒绝 </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="备注">
          <a-textarea v-model="verifyRemark"></a-textarea>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </a-card>
</template>

<script>
import { columns } from './components/colums.js'
import DealerCustomerOrderEditModal from './components/DealerCustomerOrderEditModal.vue'
import DealerCustomerInfoModal from '../dealer_customer_info/components/DealerCustomerInfoModal.vue'
import {
  delDealerCustomerOrder,
  listDealerCustomerOrder,
  selectByIdDealerCustomerOrder,
} from './api/DealerCustomerOrderApi'
import { checkPermission } from '@/utils/permissions'
import SetDealtModal from '@/views/dealer_customer_order/components/SetDealtModal.vue'
import {auditTaskBatch} from "@/api/activiti";
import {baseURL} from "@/utils/request";

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'dealerCustomerOrder',
  components: {
    DealerCustomerOrderEditModal,
    SetDealtModal,
    DealerCustomerInfoModal
  },
  data() {
    return {
      flagSystem:this.$store.getters.userInfo.flagSystem,//员工下拉
      confirmLoadingAuint: false,
      visibleAuint: false,
      statusAuint: 3,
      verifyRemark: '',
      ids: [],
      columns: columns,
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
      // dealerData: [],
      // managerData: [],
      statusChoose:{},
      managerStatus:'',
      visibleStatus:false,
      confirmLoading:false,
      addEnable: checkPermission('dealer:customer_order:add'),
      editEnable: checkPermission('dealer:customer_order:edit'),
      removeEnable: checkPermission('dealer:customer_order:remove'),
      setDealt: checkPermission('dealer:customer:set_dealt'),
      auint: checkPermission('dealer:customer:auint'),
      //上传文件地址
      uploadUrl: baseURL + '/api/dealer/customer/dealerCustomerInfo/excelReader',
      //上传文件请求头
      headers: {
        'authorization-firm': this.$store.getters.token,
      },
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
  },
  methods: {


    //导入
    uploadChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
    },
    //审核
    loseEfficacyAuint() {
      this.visibleAuint = true
      // this.ids = this.selectedRows.map((item) => {
      //   return item.id
      // })
      this.ids = this.selectedRows.map((item) => {
        return item.taskId
      })
    },
    // 现替换成审批流方法
    // handleOkAuint() {
    //   this.confirmLoadingAuint = true
    //   let obj = {
    //     verifyRemark: this.verifyRemark,
    //     ids: this.ids,
    //   }
    //   if (this.statusAuint == 3) {
    //     this.axios
    //       .post('/api/dealer/customer/dealerCustomerOrder/orderVerifyStatusFail', obj)
    //       .then((res) => {
    //         this.$message.success(res.message)
    //         this.visibleAuint = false
    //         this.verifyRemark = ''
    //         this.getData()
    //       })
    //       .finally(() => {
    //         this.confirmLoadingAuint = false
    //       })
    //   } else if (this.statusAuint == 4) {
    //     this.axios
    //       .post('/api/dealer/customer/dealerCustomerOrder/orderVerifyStatus', obj)
    //       .then((res) => {
    //         this.$message.success(res.message)
    //         this.visibleAuint = false
    //         this.verifyRemark = ''
    //         this.getData()
    //       })
    //       .finally(() => {
    //         this.confirmLoadingAuint = false
    //       })
    //   }
    // },

    handleOkAuint() {
      this.confirmLoadingAuint = true


      const _this = this
      let result = 2
      if (_this.statusAuint == 3) {
        result = 3
      }
      auditTaskBatch({ taskIds: _this.ids, result: result ,comment: _this.verifyRemark }).then((res) => {
        if (res.code == 200) {
          _this.$message.success('审核成功')
          _this.getData()
          this.visibleAuint = false
          this.verifyRemark = ''
        }else {
          _this.$message.error(res.message)
          this.visibleAuint = false
          this.verifyRemark = ''
        }
      }).finally(() => {
        this.confirmLoadingAuint = false
      })
    },
    handleCanceAuint() {
      this.visibleAuint = false
      this.confirmLoadingAuint = false
      this.verifyRemark = ''
    },
    checkStatus(row){
        if(row.registrationStatus == 1 || row.registrationStatus == 3){
          this.managerStatus = 0
        }else{
          this.managerStatus = 1
        }
       this.statusChoose = row
       this.visibleStatus = true
    },
    handleOkManagerName(){
      this.confirmLoading = true
      let params = {
        id:this.statusChoose.cusId,
        status:this.managerStatus
      }
      this.axios.post('/api/dealer/customer/customerOrder/updateOrderStatus',params).then(res => {
        if(res.code == 200){
          this.visibleStatus = false
          this.managerStatus = ''
          this.getData()
          this.$notification.success({ message: res.message })
        }
      }).finally(()=>{
        this.confirmLoading = false
      })
    },
    handleCancelManagerName(){
      this.visibleStatus = false
      this.managerStatus = ''
      this.confirmLoading = false
    },
    /**
     * 获取表格数据
     */
    getData() {
      this.tableLoading = true
      this.selectedRowKeys = []
      this.selectedRows = []
      this.searchData.verifyStatus = 1
      listDealerCustomerOrder({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))
      // this.axios.get(`/api/dealer/dealer/dealerInfo/dealerListForFirm`).then((res) => {
      //   if (res.code == 200) {
      //     this.dealerData = res.body
      //   }
      // })
      // this.axios.get(`/api/base/system/dealer/manager/managerListForFirm`).then((res) => {
      //   if (res.code == 200) {
      //     this.managerData = res.body
      //   }
      // })
    },
    /**
     * 筛选查询
     */
    getDataFilter() {
      this.page = Object.assign({}, this.page)
      this.page.current = 1
      this.getData()
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      this.page = Object.assign({}, this.page)
      this.page.current = 1
      this.page.pageSize = 10
      this.getData()
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    // 已失效置灰
    rowClassName(record, index) {
      let className;
      if (record.orderStatus === 3) className = 'dark-row'
      return className
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },

    // 设为失效
    loseEfficacy() {
      if(this.selectedRowKeys.length <= 0) {
        return this.$message.warning('请至少选择一条数据')
      }
      this.axios.get(`/api/dealer/customer/dealerCustomerOrder/updateOrderType/${this.selectedRows[0].id}`).then(res => {
        this.$message.success(res.message)
        this.getData()
      })
    },

    toHandler(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.DealerCustomerOrderEditModal.setRowData({}, 'add')
        return
      }
      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      switch (name) {
        case 'edit':
          _this.$refs.DealerCustomerOrderEditModal.setRowData(_this.selectedRows[0], 'edit')
          break
          case 'check':
          _this.$refs.DealerCustomerInfoModal.setRowData(_this.selectedRows[0], 'check','order')
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delDealerCustomerOrder(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'set_dealt':
          _this.$refs.SetDealtModal.show(_this.selectedRows[0])
          break
      }
    },
  },
  created() {
    this.getData()
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}

::v-deep .ant-table-tbody > .dark-row {
  background: #fafafa !important;
  color: #aaaaaa;
}
</style>
